function xt(t, e) {
  const n = /* @__PURE__ */ Object.create(null), s = t.split(",");
  for (let r = 0; r < s.length; r++)
    n[s[r]] = !0;
  return e ? (r) => !!n[r.toLowerCase()] : (r) => !!n[r];
}
const Nt = Object.assign, Pt = Object.prototype.hasOwnProperty, W = (t, e) => Pt.call(t, e), R = Array.isArray, D = (t) => lt(t) === "[object Map]", Lt = (t) => typeof t == "string", Q = (t) => typeof t == "symbol", V = (t) => t !== null && typeof t == "object", jt = Object.prototype.toString, lt = (t) => jt.call(t), Ct = (t) => lt(t).slice(8, -1), U = (t) => Lt(t) && t !== "NaN" && t[0] !== "-" && "" + parseInt(t, 10) === t, dt = (t, e) => !Object.is(t, e);
let v;
class Kt {
  constructor(e = !1) {
    this.detached = e, this._active = !0, this.effects = [], this.cleanups = [], this.parent = v, !e && v && (this.index = (v.scopes || (v.scopes = [])).push(
      this
    ) - 1);
  }
  get active() {
    return this._active;
  }
  run(e) {
    if (this._active) {
      const n = v;
      try {
        return v = this, e();
      } finally {
        v = n;
      }
    }
  }
  /**
   * This should only be called on non-detached scopes
   * @internal
   */
  on() {
    v = this;
  }
  /**
   * This should only be called on non-detached scopes
   * @internal
   */
  off() {
    v = this.parent;
  }
  stop(e) {
    if (this._active) {
      let n, s;
      for (n = 0, s = this.effects.length; n < s; n++)
        this.effects[n].stop();
      for (n = 0, s = this.cleanups.length; n < s; n++)
        this.cleanups[n]();
      if (this.scopes)
        for (n = 0, s = this.scopes.length; n < s; n++)
          this.scopes[n].stop(!0);
      if (!this.detached && this.parent && !e) {
        const r = this.parent.scopes.pop();
        r && r !== this && (this.parent.scopes[this.index] = r, r.index = this.index);
      }
      this.parent = void 0, this._active = !1;
    }
  }
}
function ht(t) {
  return new Kt(t);
}
function pt(t, e = v) {
  e && e.active && e.effects.push(t);
}
function $t(t) {
  v && v.cleanups.push(t);
}
const bt = (t) => {
  const e = new Set(t);
  return e.w = 0, e.n = 0, e;
}, Et = (t) => (t.w & m) > 0, _t = (t) => (t.n & m) > 0, zt = ({ deps: t }) => {
  if (t.length)
    for (let e = 0; e < t.length; e++)
      t[e].w |= m;
}, Dt = (t) => {
  const { deps: e } = t;
  if (e.length) {
    let n = 0;
    for (let s = 0; s < e.length; s++) {
      const r = e[s];
      Et(r) && !_t(r) ? r.delete(t) : e[n++] = r, r.w &= ~m, r.n &= ~m;
    }
    e.length = n;
  }
}, G = /* @__PURE__ */ new WeakMap();
let P = 0, m = 1;
const Y = 30;
let A;
const N = Symbol(""), q = Symbol("");
class Wt {
  constructor(e, n = null, s) {
    this.fn = e, this.scheduler = n, this.active = !0, this.deps = [], this.parent = void 0, pt(this, s);
  }
  run() {
    if (!this.active)
      return this.fn();
    let e = A, n = M;
    for (; e; ) {
      if (e === this)
        return;
      e = e.parent;
    }
    try {
      return this.parent = A, A = this, M = !0, m = 1 << ++P, P <= Y ? zt(this) : nt(this), this.fn();
    } finally {
      P <= Y && Dt(this), m = 1 << --P, A = this.parent, M = n, this.parent = void 0, this.deferStop && this.stop();
    }
  }
  stop() {
    A === this ? this.deferStop = !0 : this.active && (nt(this), this.onStop && this.onStop(), this.active = !1);
  }
}
function nt(t) {
  const { deps: e } = t;
  if (e.length) {
    for (let n = 0; n < e.length; n++)
      e[n].delete(t);
    e.length = 0;
  }
}
function gt(t, e) {
  t.effect && (t = t.effect.fn);
  const n = new Wt(t);
  e && (Nt(n, e), e.scope && pt(n, e.scope)), (!e || !e.lazy) && n.run();
  const s = n.run.bind(n);
  return s.effect = n, s;
}
let M = !0;
const X = [];
function vt() {
  X.push(M), M = !1;
}
function Vt() {
  X.push(M), M = !0;
}
function Bt() {
  const t = X.pop();
  M = t === void 0 ? !0 : t;
}
function w(t, e, n) {
  if (M && A) {
    let s = G.get(t);
    s || G.set(t, s = /* @__PURE__ */ new Map());
    let r = s.get(n);
    r || s.set(n, r = bt()), Ht(r);
  }
}
function Ht(t, e) {
  let n = !1;
  P <= Y ? _t(t) || (t.n |= m, n = !Et(t)) : n = !t.has(A), n && (t.add(A), A.deps.push(t));
}
function O(t, e, n, s, r, o) {
  const c = G.get(t);
  if (!c)
    return;
  let i = [];
  if (e === "clear")
    i = [...c.values()];
  else if (n === "length" && R(t)) {
    const h = Number(s);
    c.forEach((E, p) => {
      (p === "length" || p >= h) && i.push(E);
    });
  } else
    switch (n !== void 0 && i.push(c.get(n)), e) {
      case "add":
        R(t) ? U(n) && i.push(c.get("length")) : (i.push(c.get(N)), D(t) && i.push(c.get(q)));
        break;
      case "delete":
        R(t) || (i.push(c.get(N)), D(t) && i.push(c.get(q)));
        break;
      case "set":
        D(t) && i.push(c.get(N));
        break;
    }
  if (i.length === 1)
    i[0] && st(i[0]);
  else {
    const h = [];
    for (const E of i)
      E && h.push(...E);
    st(bt(h));
  }
}
function st(t, e) {
  const n = R(t) ? t : [...t];
  for (const s of n)
    s.computed && rt(s);
  for (const s of n)
    s.computed || rt(s);
}
function rt(t, e) {
  (t !== A || t.allowRecurse) && (t.scheduler ? t.scheduler() : t.run());
}
const Gt = /* @__PURE__ */ xt("__proto__,__v_isRef,__isVue"), wt = new Set(
  /* @__PURE__ */ Object.getOwnPropertyNames(Symbol).filter((t) => t !== "arguments" && t !== "caller").map((t) => Symbol[t]).filter(Q)
), Yt = /* @__PURE__ */ At(), qt = /* @__PURE__ */ At(!0), ot = /* @__PURE__ */ Ft();
function Ft() {
  const t = {};
  return ["includes", "indexOf", "lastIndexOf"].forEach((e) => {
    t[e] = function(...n) {
      const s = f(this);
      for (let o = 0, c = this.length; o < c; o++)
        w(s, "get", o + "");
      const r = s[e](...n);
      return r === -1 || r === !1 ? s[e](...n.map(f)) : r;
    };
  }), ["push", "pop", "shift", "unshift", "splice"].forEach((e) => {
    t[e] = function(...n) {
      vt();
      const s = f(this)[e].apply(this, n);
      return Bt(), s;
    };
  }), t;
}
function Jt(t) {
  const e = f(this);
  return w(e, "has", t), e.hasOwnProperty(t);
}
function At(t = !1, e = !1) {
  return function(s, r, o) {
    if (r === "__v_isReactive")
      return !t;
    if (r === "__v_isReadonly")
      return t;
    if (r === "__v_isShallow")
      return e;
    if (r === "__v_raw" && o === (t ? e ? ue : It : e ? fe : Mt).get(s))
      return s;
    const c = R(s);
    if (!t) {
      if (c && W(ot, r))
        return Reflect.get(ot, r, o);
      if (r === "hasOwnProperty")
        return Jt;
    }
    const i = Reflect.get(s, r, o);
    return (Q(r) ? wt.has(r) : Gt(r)) || (t || w(s, "get", r), e) ? i : L(i) ? c && U(r) ? i : i.value : V(i) ? t ? Tt(i) : k(i) : i;
  };
}
const Qt = /* @__PURE__ */ Ut();
function Ut(t = !1) {
  return function(n, s, r, o) {
    let c = n[s];
    if (F(c) && L(c) && !L(r))
      return !1;
    if (!t && (!he(r) && !F(r) && (c = f(c), r = f(r)), !R(n) && L(c) && !L(r)))
      return c.value = r, !0;
    const i = R(n) && U(s) ? Number(s) < n.length : W(n, s), h = Reflect.set(n, s, r, o);
    return n === f(o) && (i ? dt(r, c) && O(n, "set", s, r) : O(n, "add", s, r)), h;
  };
}
function Xt(t, e) {
  const n = W(t, e);
  t[e];
  const s = Reflect.deleteProperty(t, e);
  return s && n && O(t, "delete", e, void 0), s;
}
function Zt(t, e) {
  const n = Reflect.has(t, e);
  return (!Q(e) || !wt.has(e)) && w(t, "has", e), n;
}
function kt(t) {
  return w(t, "iterate", R(t) ? "length" : N), Reflect.ownKeys(t);
}
const te = {
  get: Yt,
  set: Qt,
  deleteProperty: Xt,
  has: Zt,
  ownKeys: kt
}, ee = {
  get: qt,
  set(t, e) {
    return !0;
  },
  deleteProperty(t, e) {
    return !0;
  }
}, Z = (t) => t, B = (t) => Reflect.getPrototypeOf(t);
function j(t, e, n = !1, s = !1) {
  t = t.__v_raw;
  const r = f(t), o = f(e);
  n || (e !== o && w(r, "get", e), w(r, "get", o));
  const { has: c } = B(r), i = s ? Z : n ? et : tt;
  if (c.call(r, e))
    return i(t.get(e));
  if (c.call(r, o))
    return i(t.get(o));
  t !== r && t.get(e);
}
function C(t, e = !1) {
  const n = this.__v_raw, s = f(n), r = f(t);
  return e || (t !== r && w(s, "has", t), w(s, "has", r)), t === r ? n.has(t) : n.has(t) || n.has(r);
}
function K(t, e = !1) {
  return t = t.__v_raw, !e && w(f(t), "iterate", N), Reflect.get(t, "size", t);
}
function ct(t) {
  t = f(t);
  const e = f(this);
  return B(e).has.call(e, t) || (e.add(t), O(e, "add", t, t)), this;
}
function it(t, e) {
  e = f(e);
  const n = f(this), { has: s, get: r } = B(n);
  let o = s.call(n, t);
  o || (t = f(t), o = s.call(n, t));
  const c = r.call(n, t);
  return n.set(t, e), o ? dt(e, c) && O(n, "set", t, e) : O(n, "add", t, e), this;
}
function at(t) {
  const e = f(this), { has: n, get: s } = B(e);
  let r = n.call(e, t);
  r || (t = f(t), r = n.call(e, t)), s && s.call(e, t);
  const o = e.delete(t);
  return r && O(e, "delete", t, void 0), o;
}
function ft() {
  const t = f(this), e = t.size !== 0, n = t.clear();
  return e && O(t, "clear", void 0, void 0), n;
}
function $(t, e) {
  return function(s, r) {
    const o = this, c = o.__v_raw, i = f(c), h = e ? Z : t ? et : tt;
    return !t && w(i, "iterate", N), c.forEach((E, p) => s.call(r, h(E), h(p), o));
  };
}
function z(t, e, n) {
  return function(...s) {
    const r = this.__v_raw, o = f(r), c = D(o), i = t === "entries" || t === Symbol.iterator && c, h = t === "keys" && c, E = r[t](...s), p = n ? Z : e ? et : tt;
    return !e && w(
      o,
      "iterate",
      h ? q : N
    ), {
      // iterator protocol
      next() {
        const { value: x, done: a } = E.next();
        return a ? { value: x, done: a } : {
          value: i ? [p(x[0]), p(x[1])] : p(x),
          done: a
        };
      },
      // iterable protocol
      [Symbol.iterator]() {
        return this;
      }
    };
  };
}
function T(t) {
  return function(...e) {
    return t === "delete" ? !1 : this;
  };
}
function ne() {
  const t = {
    get(o) {
      return j(this, o);
    },
    get size() {
      return K(this);
    },
    has: C,
    add: ct,
    set: it,
    delete: at,
    clear: ft,
    forEach: $(!1, !1)
  }, e = {
    get(o) {
      return j(this, o, !1, !0);
    },
    get size() {
      return K(this);
    },
    has: C,
    add: ct,
    set: it,
    delete: at,
    clear: ft,
    forEach: $(!1, !0)
  }, n = {
    get(o) {
      return j(this, o, !0);
    },
    get size() {
      return K(this, !0);
    },
    has(o) {
      return C.call(this, o, !0);
    },
    add: T("add"),
    set: T("set"),
    delete: T("delete"),
    clear: T("clear"),
    forEach: $(!0, !1)
  }, s = {
    get(o) {
      return j(this, o, !0, !0);
    },
    get size() {
      return K(this, !0);
    },
    has(o) {
      return C.call(this, o, !0);
    },
    add: T("add"),
    set: T("set"),
    delete: T("delete"),
    clear: T("clear"),
    forEach: $(!0, !0)
  };
  return ["keys", "values", "entries", Symbol.iterator].forEach((o) => {
    t[o] = z(
      o,
      !1,
      !1
    ), n[o] = z(
      o,
      !0,
      !1
    ), e[o] = z(
      o,
      !1,
      !0
    ), s[o] = z(
      o,
      !0,
      !0
    );
  }), [
    t,
    n,
    e,
    s
  ];
}
const [
  se,
  re,
  oe,
  ce
] = /* @__PURE__ */ ne();
function St(t, e) {
  const n = e ? t ? ce : oe : t ? re : se;
  return (s, r, o) => r === "__v_isReactive" ? !t : r === "__v_isReadonly" ? t : r === "__v_raw" ? s : Reflect.get(
    W(n, r) && r in s ? n : s,
    r,
    o
  );
}
const ie = {
  get: /* @__PURE__ */ St(!1, !1)
}, ae = {
  get: /* @__PURE__ */ St(!0, !1)
}, Mt = /* @__PURE__ */ new WeakMap(), fe = /* @__PURE__ */ new WeakMap(), It = /* @__PURE__ */ new WeakMap(), ue = /* @__PURE__ */ new WeakMap();
function le(t) {
  switch (t) {
    case "Object":
    case "Array":
      return 1;
    case "Map":
    case "Set":
    case "WeakMap":
    case "WeakSet":
      return 2;
    default:
      return 0;
  }
}
function de(t) {
  return t.__v_skip || !Object.isExtensible(t) ? 0 : le(Ct(t));
}
function k(t) {
  return F(t) ? t : Rt(
    t,
    !1,
    te,
    ie,
    Mt
  );
}
function Tt(t) {
  return Rt(
    t,
    !0,
    ee,
    ae,
    It
  );
}
function Rt(t, e, n, s, r) {
  if (!V(t) || t.__v_raw && !(e && t.__v_isReactive))
    return t;
  const o = r.get(t);
  if (o)
    return o;
  const c = de(t);
  if (c === 0)
    return t;
  const i = new Proxy(
    t,
    c === 2 ? s : n
  );
  return r.set(t, i), i;
}
function F(t) {
  return !!(t && t.__v_isReadonly);
}
function he(t) {
  return !!(t && t.__v_isShallow);
}
function f(t) {
  const e = t && t.__v_raw;
  return e ? f(e) : t;
}
const tt = (t) => V(t) ? k(t) : t, et = (t) => V(t) ? Tt(t) : t;
function L(t) {
  return !!(t && t.__v_isRef === !0);
}
function pe() {
  const t = [], e = [], n = [], s = [], r = new MutationObserver((a) => {
    const l = [], b = [], _ = [], I = [];
    for (const d of a)
      if (d.type === "childList" && (d.addedNodes.forEach((u) => u.nodeType === Node.ELEMENT_NODE && l.push(u)), d.removedNodes.forEach((u) => u.nodeType === Node.ELEMENT_NODE && b.push(u))), d.type === "attributes") {
        const { target: u, attributeName: g, oldValue: S } = d;
        u.hasAttribute(g), u.hasAttribute(g) && S == null ? _.push([u, g]) : u.hasAttribute(g) ? (I.push([u, g]), _.push([u, g])) : I.push([u, g]);
      }
    I.forEach(([d, u]) => {
      s.forEach((g) => g(d, u));
    }), _.forEach(([d, u]) => {
      n.forEach((g) => g(d, u));
    }), b.forEach((d) => {
      e.forEach((u) => u(d));
    }), l.forEach((d) => {
      t.forEach((u) => u(d));
    });
  });
  let o = !1;
  return { start: (a) => {
    o || (r.observe(a, { subtree: !0, childList: !0, attributes: !0, attributeOldValue: !0 }), o = !0);
  }, stop: () => {
    o && (r.disconnect(), o = !1);
  }, onElementAdded: (a) => t.push(a), onElementRemoved: (a) => e.push(a), onAttributeAdded: (a) => n.push(a), onAttributeRemoved: (a) => s.push(a) };
}
function y(t, e, n = {}) {
  return (s) => {
    const { controller: r, parentScope: o } = s, c = /* @__PURE__ */ new WeakMap(), i = n.attributeName ? n.attributeName(s) : `data-${r.identifier}-${t}`, h = n.selector ? n.selector(s) : `[${i}]`, E = (l) => l === i, p = (l) => l.matches(h);
    return {
      name: t,
      handles: E,
      match: p,
      handle: (l) => {
        if (!p(l))
          return;
        const b = o.run(() => ht());
        c.set(l, b);
        const _ = (S) => b.run(() => gt(S)), I = (S) => b.run(() => $t(S)), d = () => l.getAttribute(i);
        return e(l, { effect: _, cleanup: I, attribute: d, value: (S = d()) => {
          const H = r[S];
          return typeof H == "function" ? H.bind(r)(l) : H;
        }, assign: (S) => {
          r[d()] = S;
        } });
      },
      cleanup: (l) => {
        c.has(l) && c.get(l).stop();
      }
    };
  };
}
const be = y("bind", (t, { attribute: e, value: n, effect: s }) => {
  e().split(" ").forEach((o) => {
    const [c, i] = o.split(":");
    s(() => {
      switch (c) {
        case "class":
          return Ee(t, n(i));
        case "value":
          return mt(t, "value", n(i));
        case "selected":
        case "checked":
          return _e(t, c, n(i));
        default:
          return Ot(t, c, n(i));
      }
    });
  });
});
function Ee(t, e) {
  t._undoClass && t._undoClass();
  let n = [];
  const s = e.split(" ").filter((r) => !t.classList.contains(r)).filter(Boolean);
  t.classList.add(...s), n = n.concat(s), t._undoClass = () => t.classList.remove(...n);
}
function _e(t, e, n) {
  Ot(t, e, n), mt(t, e, n);
}
function mt(t, e, n) {
  we(t, e, n);
}
function Ot(t, e, n) {
  [null, void 0, !1].includes(n) && ge(e) ? t.removeAttribute(e) : (ve(e) && (n = e), Ae(t, e, n));
}
function ge(t) {
  return !["aria-pressed", "aria-checked", "aria-expanded", "aria-selected"].includes(t);
}
function ve(t) {
  return [
    "disabled",
    "checked",
    "required",
    "readonly",
    "hidden",
    "open",
    "selected",
    "autofocus",
    "itemscope",
    "multiple",
    "novalidate",
    "allowfullscreen",
    "allowpaymentrequest",
    "formnovalidate",
    "autoplay",
    "controls",
    "loop",
    "muted",
    "playsinline",
    "default",
    "ismap",
    "reversed",
    "async",
    "defer",
    "nomodule"
  ].includes(t);
}
function we(t, e, n) {
  t[e] !== n && (t[e] = n);
}
function Ae(t, e, n) {
  t.getAttribute(e) != n && t.setAttribute(e, n);
}
const Se = y(
  "cloak",
  (t) => t.removeAttribute("data-cloak"),
  {
    attributeName: () => "data-cloak",
    selector: (t) => `[data-cloak="${t.controller.identifier}"]`
  }
), Me = y("each", (t, { effect: e, cleanup: n, value: s }) => {
  e(() => {
    t._undoEach && t._undoEach(), Ie(t, s());
  }), n(() => t._undoEach && t._undoEach());
});
function Ie(t, e) {
  const n = [];
  let s = t;
  e.forEach((r, o) => {
    const c = t.content.cloneNode(!0).firstElementChild;
    c.$item = r, c.$index = o, s.after(c), n.push(c), s = c;
  }), t._undoEach = () => n.forEach((r) => r.remove());
}
const Te = y("html", (t, { effect: e, value: n }) => {
  e(() => {
    t.innerHTML = n();
  });
}), Re = y("if", (t, { effect: e, cleanup: n, value: s }) => {
  e(() => {
    s() ? me(t) : ut(t);
  }), n(() => ut(t));
});
function me(t) {
  if (t._undoIf)
    return;
  const e = t.content.cloneNode(!0).firstElementChild;
  t.after(e), t._undoIf = () => e.remove();
}
function ut(t) {
  t._undoIf && (t._undoIf(), delete t._undoIf);
}
const Oe = y("model", (t, e) => {
  t.tagName === "SELECT" ? ye(t, e) : t.type === "radio" ? xe(t, e) : t.type === "checkbox" ? Ne(t, e) : Pe(t, e);
});
function ye(t, { assign: e, value: n, effect: s, cleanup: r }) {
  Array.from(t.options).forEach((c) => {
    c.hasAttribute("selected") && e(t.value);
  }), s(() => {
    t.value = n();
  });
  const o = () => e(t.value);
  t.addEventListener("change", o), r(() => t.removeEventListener("change", o));
}
function xe(t, { assign: e, value: n, effect: s, cleanup: r }) {
  t.hasAttribute("checked") && e(t.value), s(() => {
    t.checked = t.value == n();
  });
  const o = () => t.checked && e(t.value);
  t.addEventListener("change", o), r(() => t.removeEventListener("change", o));
}
function Ne(t, { assign: e, value: n, effect: s, cleanup: r }) {
  t.hasAttribute("checked") && e(t.checked), s(() => {
    t.checked = n();
  });
  const o = () => e(t.checked);
  t.addEventListener("change", o), r(() => t.removeEventListener("change", o));
}
function Pe(t, { assign: e, value: n, effect: s, cleanup: r }) {
  t.hasAttribute("value") && e(t.value), s(() => {
    t.value = n();
  });
  const o = () => e(t.value);
  t.addEventListener("input", o), r(() => t.removeEventListener("input", o));
}
const Le = y("show", (t, { effect: e, value: n }) => {
  const s = t.style.display;
  e(() => {
    t.style.display = n() ? s : "none";
  });
}), je = y("text", (t, { effect: e, cleanup: n, value: s }) => {
  e(() => {
    t.textContent = s();
  });
});
function Ke(t) {
  const e = k(t.constructor.state()), n = ht(), s = pe(), r = { controller: t, observer: s, parentScope: n }, o = [
    Se(r),
    Re(r),
    Me(r),
    Oe(r),
    be(r),
    Le(r),
    je(r),
    Te(r)
  ];
  s.onElementAdded((a) => {
    J(a, o);
  }), s.onElementRemoved((a) => {
    yt(a, o);
  }), s.onAttributeRemoved((a, l) => {
    var b;
    (b = o.find((_) => _.handles(l))) == null || b.cleanup(a);
  }), s.onAttributeAdded((a, l) => {
    var b;
    (b = o.find((_) => _.handles(l))) == null || b.handle(a);
  });
  const c = (a) => n.run(() => gt(a)), i = (a, l, b = !1) => {
    let _ = !0, I;
    c(() => {
      const d = t[a];
      (!_ || b) && (vt(), l(d, I), Vt()), I = d, _ = !1;
    });
  }, h = () => {
    s.start(t.element), J(t.element, o);
  }, E = () => {
    s.stop(), n.stop();
  }, p = t.disconnect.bind(t);
  Object.assign(t, { state: e, watchEffect: c, watch: i, disconnect: () => {
    E(), p();
  } }), Ce(t, e), h();
}
function J(t, e) {
  e.forEach((n) => n.handle(t));
  for (const n of t.children)
    J(n, e);
}
function yt(t, e) {
  e.forEach((n) => n.cleanup(t));
  for (const n of t.children)
    yt(n, e);
}
function Ce(t, e) {
  Object.keys(e).forEach((n) => {
    Object.defineProperty(t, n, {
      get() {
        return e[n];
      },
      set(s) {
        e[n] = s;
      }
    });
  });
}
export {
  Ke as default
};
